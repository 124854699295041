import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Drawer, AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PersonIcon from '@mui/icons-material/Person';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PasswordIcon from '@mui/icons-material/Password';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Card, CardContent, Grid } from '@mui/material';
import { Avatar } from '@mui/material';
import { useAuth } from './AuthProvider';

function NavMenu() {
    const { userData, setIsAuthenticated, setUserData } = useAuth();
    const [open, setOpen] = useState(false);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const navigate = useNavigate();

    // Define las opciones de men� para el rol de Administrador
    const adminMenuOptions = [
        { label: 'Home', icon: <HomeIcon />, to: '/' },
        { label: 'Home', icon: <HomeIcon />, to: '/' },
        { label: 'Tipos de Estampillas', icon: <TodayIcon />, to: '/tipoestampillalist' },
        { label: 'Profesionales', icon: <AccountCircleIcon />, to: '/profesionaleslist' },
        /*{ label: 'Gremiales', icon: <CalendarMonthIcon />, to: '/eventoscalendar' },*/
        { label: 'Reporte Estampillas', icon: <ChecklistIcon />, to: '/reporteestampillasmes' },
        { label: 'Reporte Paciente', icon: <ChecklistIcon />, to: '/reportepacientesfecha' },
    ];

    // Define las opciones de men� para el rol de Profesional
    const professionalMenuOptions = [
        { label: 'Home', icon: <HomeIcon />, to: '/' },
        { label: 'Home', icon: <HomeIcon />, to: '/' },
        { label: 'Mis Estampillas', icon: <ListAltIcon />, to: '/estampillaslist' },
        { label: 'Nueva Estampilla', icon: <PostAddIcon />, to: '/generarestampilla' },
        { label: 'Estadisticas', icon: <AssessmentIcon />, to: '/estadisticasprofesional' },
    ];

    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
        // Aqu� puedes realizar otras acciones o actualizaciones en NavMenu
    }, [userData]);

    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleLogout = () => {
        setShowLogoutConfirmation(true); // Mostrar la ventana de confirmaci�n al hacer clic en Cerrar Sesi�n
    };

    const handleLogoutConfirm = () => {
        //// Eliminar los datos del usuario del localStorage
        //localStorage.removeItem('userData');
        //// Actualizar el estado de autenticaci�n a false en el AuthProvider
        //setIsAuthenticated(false);
        //// Redirigir al componente de login sin recargar la p�gina
        //navigate('/LoginUser');
        // Eliminar los datos del usuario del localStorage
        localStorage.removeItem('userData');
        // Actualizar el estado de autenticaci�n a false en el AuthProvider
        setIsAuthenticated(false);
        // Eliminar los datos del usuario del estado en el AuthProvider
        setUserData(null);
        // Cerrar el di�logo de confirmaci�n
        setShowLogoutConfirmation(false);
        // Redirigir al componente de login sin recargar la p�gina
        navigate('/LoginUser');
    };

    const handleLogoutCancel = () => {
        setShowLogoutConfirmation(false); // Ocultar la ventana de confirmaci�n al cancelar el cierre de sesi�n
    };

    // Determina qu� opciones de men� mostrar en funci�n del rol del usuario
    const menuOptions = userData && userData.role === 'Administrador' ? adminMenuOptions : professionalMenuOptions;

    return (
        <div style={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* Agregar el logo en forma de Avatar */}
                    <Avatar alt="Logo" src="/images/logoavatar.png" sx={{ width: 40, height: 40, marginRight: 1 }} />
                    <Typography variant="h6" noWrap component="div">
                        GESTOR DE ESTAMPILLAS
                    </Typography>

                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        {userData ? (
                            <>
                                <Avatar alt="User Avatar" src={userData.role === 'Administrador' ? '/images/adminavatar.png' : '/images/doctoravatar.png'} sx={{backgroundColor: 'white'} }>
                                    {/*<PersonIcon />*/}
                                </Avatar>
                                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                    {userData.nombre}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Avatar alt="User Avatar">
                                    <PersonIcon />
                                </Avatar>
                                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                    {/* Texto de bienvenida cuando no hay informaci�n de usuario */}
                                    Bienvenido/a
                                </Typography>
                            </>
                        )}
                    </div>
                </Toolbar>
            </AppBar>



            <div style={{ width: 240 }}>
                <Toolbar />
                <Toolbar />
                <Drawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
                    }}
                    open={open}
                    onClose={handleDrawerToggle}
                >
                    <List>
                        {menuOptions.map((option) => (
                            <ListItem
                                button
                                component={option.to ? Link : 'button'}
                                to={option.to}
                                onClick={option.onClick}
                                key={option.label}
                            >
                                <ListItemIcon>{option.icon}</ListItemIcon>
                                <ListItemText primary={option.label} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {userData && ( // Renderiza el bot�n de "Cerrar Sesi�n" solo si el usuario est� logueado
                            <ListItem button component={Link} to="/resetpass">
                                <ListItemIcon>
                                    <PasswordIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cambiar Password" />
                            </ListItem>)}
                        {userData && (
                            <ListItem button onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cerrar Sesion" />
                            </ListItem>
                        )}
                    </List>
                </Drawer>
            </div>

            {/* Ventana de confirmaci�n de cierre de sesi�n */}
            <Dialog
                open={showLogoutConfirmation}
                onClose={handleLogoutCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmar cierre de Sesion</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" component="div" id="alert-dialog-description">
                        Estas seguro de que deseas cerrar sesion?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogoutCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleLogoutConfirm} color="primary" variant="contained" autoFocus>
                        Cerrar sesion
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NavMenu;


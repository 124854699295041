import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Paper, Stack, CircularProgress } from '@mui/material';
import { saveAs } from 'file-saver';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma espa�ol
import Breadcrumb from './Breadcrumb';
/*import { Paper, Typography, TextField, Button, Alert, Stack } from '@mui/material';*/
import axios from 'axios';
axios.defaults.headers.common['Origin'] = window.location.origin;
dayjs.locale('es');
function ReporteEstampillasMes() {
    const [data, setData] = useState([]);
    const fechaActual = dayjs(); // Obtener la fecha actual
    const [fechaDesde, setFechaDesde] = useState(fechaActual);
    const [fechaHasta, setFechaHasta] = useState(fechaActual);
    const [totalRegistros, setTotalRegistros] = useState(null); // Estado para la cantidad de registros
    const [botonesDeshabilitados, setBotonesDeshabilitados] = useState(true); // Estado para deshabilitar los botones
    const [errorFecha, setErrorFecha] = useState(false); // Estado para manejar errores de fecha
    const [isLoading, setIsLoading] = useState(false);



    //useEffect(() => {
    //    // Hacer una solicitud HTTP para obtener los datos JSON desde el servidor
    //    obtenerDatos();
    //}, []);

    useEffect(() => {
        // Verifica si ambas fechas est�n ingresadas y si "fecha hasta" es mayor o igual a "fecha desde"
        const fechasValidas = fechaDesde !== null && fechaHasta !== null && fechaHasta >= fechaDesde;

        // Actualiza el estado de los botones y el estado de error de fecha
        setBotonesDeshabilitados(!fechasValidas);
        setErrorFecha(!fechasValidas);
    }, [fechaDesde, fechaHasta]);

    const obtenerDatos = () => {
        setIsLoading(true); // Activar indicador de carga
        // Realizar la solicitud con las fechas seleccionadas
        const apiData = {
            fechaDesde: fechaDesde,
            fechaHasta: fechaHasta,
        };

        axios.post('gremiales/ObtenerDetalleEstampillasMes', apiData)
            .then(response => {
                setData(response.data);
                setTotalRegistros(response.data.length); // Actualiza el estado con la cantidad de registros
                setIsLoading(false); // Desactivar indicador de carga
            })
            .catch(error => {
                console.error('Error al obtener datos:', error);
                setIsLoading(false); // Desactivar indicador de carga
            });
    };

    const generarCSV = () => {
        // T�tulos de los campos
        const titulos = ['ID', 'Codigo', 'Fecha', 'CUIT', 'Valor', 'Valor Gremial', 'Numero de Estampilla', 'DNI del Paciente', 'Paciente'];

        // Datos JSON a CSV
        const csvData = [titulos]
            .concat(data.map(item => [item.id, item.codigo, item.fecha, item.cuit, item.valor, item.valorGremial, item.nroEstampilla, item.dniPaciente, item.nombrePaciente]))
            .map(row => row.join(',')) // Convertir cada fila en una cadena CSV
            .join('\n'); // Unir todas las filas con saltos de l�nea

        // Crear el Blob y descargar el archivo
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, 'datos_estampillas.csv');
    };

    //const generarCSV = () => {
    //    // Convertir datos JSON a CSV y descargarlo
    //    const csvData = data.map(item => `${item.id},${item.codigo},${item.fecha},${item.cuit},${item.valor},${item.nroEstampilla},${item.dniPaciente}`).join('\n');
    //    const blob = new Blob([csvData], { type: 'text/csv' });
    //    saveAs(blob, 'datos_estampillas.csv');
    //};

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Breadcrumb text='Reportes' />
            <Typography variant="h5" component="h1" align="left" gutterBottom>
                Reporte Estampillas Generadas por Fecha
            </Typography>
            <Paper elevation={3} style={{ padding: '20px', width: '100%', display: 'flex', flexDirection: 'column', gap: 8, margin: '0 auto', }}>
                <div>
                    <Stack direction="row" spacing={1} sx={{ mb: 1, alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                            <DatePicker
                                label="Desde"
                                value={fechaDesde}
                                onChange={date => setFechaDesde(date)}
                                format="DD/MM/YYYY"
                                inputFormat="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Hasta"
                                value={fechaHasta}
                                onChange={date => setFechaHasta(date)}
                                format="DD/MM/YYYY"
                                inputFormat="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                        {errorFecha && <Typography variant="body2" color="error">La fecha hasta debe ser mayor o igual a la fecha desde.</Typography>}
                    </Stack>   
                    
                    <Stack direction="row" spacing={1} sx={{ mb: 1, alignItems: 'center' }}>
                        <Button onClick={obtenerDatos} variant="outlined" disabled={botonesDeshabilitados}>{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Obtener Datos'}</Button>
                        <Typography variant="body1">
                            {totalRegistros !== null ? `Total de registros recibidos: ${totalRegistros}` : 'Sin Datos'}
                        </Typography>
                    </Stack>
                    <Button onClick={generarCSV} variant="outlined" disabled={botonesDeshabilitados || totalRegistros === null || totalRegistros === 0}>Generar CSV</Button>            
                    
                    {/* Renderizar datos y otros componentes aqu� */}
                </div>
            </Paper>
        </div>
    );
}

export default ReporteEstampillasMes;
import React, { Component } from 'react';
import { useState } from 'react';
import { Alert, Typography, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, Tooltip } from '@mui/material';
import { FormControlLabel, Checkbox, Snackbar } from '@mui/material';
import { DataGrid, GridActionsCellItem, esES } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import AddIcon from '@mui/icons-material/Add';
import LockResetIcon from '@mui/icons-material/LockReset';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumb from './Breadcrumb';


/*import { Paper, Typography, TextField, Button, Alert, Stack } from '@mui/material';*/
import axios from 'axios';

axios.defaults.headers.common['Origin'] = window.location.origin;

class ProfesionalesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            loading: true,
            openModal: false,

            apellido: '',
            nombre: '',
            email: '',
            cuit: '',
            matricula: '',
            nroAfiliadoCaja: '',
            habilitado: false,
            gremiales: [],
            gremial: '',

            selectedRow: null,
            isEditing: false, // True es un evento nuevo y False es Edici�n de un evento existente;
            showSnackbar: false,
            snackbarMessage: '',
            snackbarSeverity: 'success',
            error: '',            
            confirmDisable: false,
            confirmDelete: false,
            confirmReset: false,
        };
    }

    isAuthenticated() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        return userData !== null;
    }

    componentDidMount() {
        this.ObtenerProfesionales();
        this.ObtenerGremiales();
    }

    handleConfirmDisable = () => {
        this.setState({ confirmDisable: true });
    };

    handleConfirmDelete = () => {
        this.setState({ confirmDelete: true });
    };

    handleConfirmReset = () => {
        this.setState({ confirmReset: true });
    };

    handleShowSnackbar = (message, severity) => {
        this.setState({
            showSnackbar: true,
            snackbarMessage: message,
            snackbarSeverity: severity || 'success', // Si no se proporciona un tipo de alerta, se usa 'success' por defecto
        });
    };
    

    handleEdit = (selectedRow) => {
        console.log('Editar profesional:', selectedRow);
        // L�gica para editar el profesional con el ID proporcionado        
        const isEditing = true; // Comprueba si hay un evento seleccionado
        this.setState({
            selectedRow: selectedRow,
            openModal: true,
            apellido: selectedRow.apellido,
            nombre: selectedRow.nombre,
            email: selectedRow.email,
            cuit: selectedRow.cuit,
            matricula: selectedRow.matricula,
            nroAfiliadoCaja: selectedRow.nroAfiliadoCaja,
            habilitado: selectedRow.habilitado,
            gremial: selectedRow.idGremial,            
            isEditing: isEditing,
        });
    };

    handleDisable = (selectedRow) => {
        console.log('Deshabilitar profesional:', selectedRow);
        // L�gica para deshabilitar el profesional con el ID proporcionado        
        this.setState({
            selectedRow: selectedRow,
            apellido: selectedRow.apellido,
            nombre: selectedRow.nombre,
            email: selectedRow.email,
            cuit: selectedRow.cuit,
            matricula: selectedRow.matricula,
            nroAfiliadoCaja: selectedRow.nroAfiliadoCaja,
            habilitado: selectedRow.habilitado,
            gremial: selectedRow.idGremial,
        });
        this.handleConfirmDisable();
        //this.handleDisableEvent();


    };

    handleDelete = (selectedRow) => {
        console.log('Eliminar profesional:', selectedRow);
        // L�gica para eliminar el profesional con el ID proporcionado
        this.setState({
            selectedRow: selectedRow,
            apellido: selectedRow.apellido,
            nombre: selectedRow.nombre,
            email: selectedRow.email,
            cuit: selectedRow.cuit,
            matricula: selectedRow.matricula,
            nroAfiliadoCaja: selectedRow.nroAfiliadoCaja,
            habilitado: selectedRow.habilitado,
            gremial: selectedRow.idGremial,
        });
        this.handleConfirmDelete();
        //this.handleDisableEvent();
    };

    handleReset = (selectedRow) => {
        console.log('Reset Password profesional:', selectedRow);
        // L�gica para eliminar el profesional con el ID proporcionado
        this.setState({
            selectedRow: selectedRow,
            apellido: selectedRow.apellido,
            nombre: selectedRow.nombre,
            email: selectedRow.email,
            cuit: selectedRow.cuit,
            matricula: selectedRow.matricula,
            nroAfiliadoCaja: selectedRow.nroAfiliadoCaja,
            habilitado: selectedRow.habilitado,
            gremial: selectedRow.idGremial,
        });
        this.handleConfirmReset();
        //this.handleDisableEvent();
    };

    handleOpenModal = () => {
        const isEditing = !!this.state.selectedRow; // Comprueba si hay un evento seleccionado
        this.setState({
            openModal: true,
            isEditing: isEditing,
        });
    };

    handleCloseModal = () => {
        this.setState({
            openModal: false,
            apellido: '',
            nombre: '',
            email: '',
            cuit: '',
            matricula: '',
            nroAfiliadoCaja: '',
            habilitado: false,
            selectedRow: null,
            isEditing: false, // Restablecer el valor de isEditing
            error: '',
        });
    };

    renderCell = (params) => {
        const { row } = params;
        const handleEdit = () => this.handleEdit(row);
        const handleDelete = () => this.handleDelete(row);
        const handleDisable = () => this.handleDisable(row);
        const handleReset = () => this.handleReset(row);

        return (
            <>
                <Tooltip title="Editar" placement="bottom">
                    <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Editar"
                    className="textPrimary"
                    onClick={handleEdit}
                    color="inherit"
                    />
                </Tooltip>
                <Tooltip title="Cambiar Estado" placement="bottom">
                    <GridActionsCellItem
                        icon={<LoopIcon />}
                        label="Deshabilitar"
                        onClick={handleDisable}
                        color="inherit"
                        />
                </Tooltip>
                <Tooltip title="Eliminar" placement="bottom">
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDelete}
                        color="inherit"
                    />
                </Tooltip>
                <Tooltip title="Recuperar Password" placement="bottom">
                    <GridActionsCellItem
                        icon={<LockResetIcon />}
                        label="Recuperar Password"
                        onClick={handleReset}
                        color="inherit"
                    />
                </Tooltip>
            </>
        );
    };


    handleAddProfesional = () => {
        const { apellido, nombre, email, cuit, matricula, nroAfiliadoCaja, habilitado, gremial } = this.state;

        // Validar campos obligatorios
        if (!apellido || !nombre || !email || !cuit || !matricula || !nroAfiliadoCaja || !gremial) {
            this.setState({ error: 'Por favor, completa todos los campos obligatorios' });
            return;
        }

        // Resto de la l�gica para agregar el evento
        axios
            .post('profesionales/AgregarProfesional', {
                apellido: apellido,
                nombre: nombre,
                email: email,
                cuit: cuit,
                matricula: matricula,
                nroAfiliadoCaja: nroAfiliadoCaja,
                habilitado: habilitado,
                idGremial: gremial,
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Profesional agregado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerProfesionales();
                this.handleShowSnackbar(response.data, 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.handleCloseModal();
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al agregar el profesional:', error);
                this.handleShowSnackbar('Error al agregar el profesional', 'error');
                // Cerrar el di�logo modal y limpiar los campos
                this.handleCloseModal();
            });

        
    };

    handleEditProfesional = () => {
        const { apellido, nombre, email, cuit, matricula, nroAfiliadoCaja, habilitado, gremial, selectedRow } = this.state;

        // Validar campos obligatorios
        if (!apellido || !nombre || !email || !cuit || !matricula || !nroAfiliadoCaja || !gremial) {
            this.setState({ error: 'Por favor, completa todos los campos obligatorios' });
            return;
        }

       
        // Resto de la l�gica para agregar el evento
        axios
            .post('profesionales/EditarProfesional', {
                id: selectedRow.id,
                apellido: apellido,
                nombre: nombre,
                email: email,
                cuit: cuit,
                matricula: matricula,
                nroAfiliadoCaja: nroAfiliadoCaja,
                habilitado: habilitado,
                idGremial: gremial,
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Profesional modificado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerProfesionales();
                this.handleShowSnackbar('Profesional modificado con exito', 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.handleCloseModal();
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al editar el profesional:', error);
                // Cerrar el di�logo modal y limpiar los campos
                this.handleShowSnackbar('Error al modificar el profesional', 'error');
                this.handleCloseModal();
            });
    };

    handleDisableProfesional = () => {
        
        const { apellido, nombre, email, cuit, matricula, nroAfiliadoCaja, habilitado, gremial, selectedRow } = this.state;

        // Resto de la l�gica para agregar el evento
        axios
            .post('profesionales/CambiarEstadoProfesional', {
                id: selectedRow.id,
                apellido: apellido,
                nombre: nombre,
                email: email,
                cuit: cuit,
                matricula: matricula,
                nroAfiliadoCaja: nroAfiliadoCaja,
                habilitado: habilitado ? false : true,
                idGremial: gremial,      
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Profesional cambio estado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerProfesionales();
                this.handleShowSnackbar('Cambio de estado realizado con exito', 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.setState({ confirmDisable: false });
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al cambiar estado del profesional:', error);
                // Cerrar el di�logo modal y limpiar los campos
                this.handleShowSnackbar('No se pudo realizar el cambio de estado', 'error');
                this.setState({ confirmDisable: false });
            });
    };

    handleResetProfesional = () => {

        const { apellido, nombre, email, cuit, matricula, nroAfiliadoCaja, habilitado, gremial, selectedRow } = this.state;

        // Resto de la l�gica para agregar el evento
        axios
            .post('login/RecoverPassword', {
                username: email,
                password: '',
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Recupero con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.handleShowSnackbar(response.data, 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.setState({ confirmReset: false });
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error recupero:', error);
                // Cerrar el di�logo modal y limpiar los campos
                this.handleShowSnackbar('No se pudo realizar el recupero', 'error');
                this.setState({ confirmReset: false });
            });
    };

    handleDeleteProfesional = () => {

        const { apellido, nombre, email, cuit, matricula, nroAfiliadoCaja, habilitado, gremial, selectedRow } = this.state;

        // Resto de la l�gica para agregar el evento
        axios
            .post('profesionales/EliminarProfesional', {
                id: selectedRow.id,
                apellido: apellido,
                nombre: nombre,
                email: email,
                cuit: cuit,
                matricula: matricula,
                nroAfiliadoCaja: nroAfiliadoCaja,
                habilitado: habilitado,
                idGremial: gremial,
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Profesional eliminado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerProfesionales();
                this.handleShowSnackbar('Profesional eliminado con exito', 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.setState({ confirmDelete: false });
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al eliminar el profesional:', error);
                // Cerrar el di�logo modal y limpiar los campos
                this.handleShowSnackbar('No se puede eliminar el profesional', 'error');
                this.setState({ confirmDelete: false });
            });
    };

    
    ObtenerProfesionales = () => {
        axios
            .get('profesionales/obtenerprofesionales')
            .then((response) => {
                this.setState({ rows: response.data, loading: false });
            })
            .catch((error) => {
                console.error('Error obteniendo profesionales', error);
            });
    };

    ObtenerGremiales = () => {
        axios
            .get('profesionales/obtenergremiales')
            .then((response) => {
                this.setState({ gremiales: response.data }, () => {
                    console.log(this.state.gremiales); // Esto mostrar� el estado actualizado
                });
            })
            .catch((error) => {
                console.error('Error obteniendo gremiales', error);
            });
    };

    render() {

        const { error, rows, loading, openModal, apellido, nombre, email, cuit, matricula, nroAfiliadoCaja, habilitado, gremiales, gremial, isEditing, showSnackbar, snackbarSeverity, snackbarMessage, confirmDisable, confirmDelete, confirmReset } = this.state;
        console.log(this.state.gremiales.nombre);
        const columns = [
            { field: 'apellido', headerName: 'Apellido', width: 140 },
            { field: 'nombre', headerName: 'Nombre', width: 140 },
            { field: 'email', headerName: 'Email', width: 200 },
            { field: 'cuit', headerName: 'C.U.I.T.', width: 120 },
            { field: 'matricula', headerName: 'Matricula', width: 80 },
            { field: 'nroAfiliadoCaja', headerName: 'Nro. Afiliado Caja', width: 80 },
            {
                field: 'GremialA', headerName: 'Gremial', width: 80,
                valueGetter: (params) => {
                    const GremialId = params.row.idGremial;
                    const gremial = this.state.gremiales.find(gremial => gremial.id === GremialId);
                    return gremial ? gremial.sigla : '';
                },
            },
            { field: 'habilitado', headerName: 'Habilitado', type: 'boolean', width: 100 },            
            {
                field: 'actions',
                headerName: 'Acciones',
                width: 160,
                sortable: false,
                filterable: false,
                renderCell: this.renderCell,
            },
        ];

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Breadcrumb text='Profesionales' />
                <Typography variant="h5" component="h1" align="left" gutterBottom>
                    Profesionales
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                    <Button variant="outlined" size="small" onClick={this.handleOpenModal} startIcon={<AddIcon />}>
                        Agregar Profesional
                    </Button>
                </Stack>
                <DataGrid 
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    loading={loading}
                    autoHeight={true}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    
                />

                <Dialog open={openModal} onClose={this.handleCloseModal}>
                    <DialogTitle>{isEditing ? 'Editar Profesional' : 'Nuevo Profesional' }</DialogTitle>
                    <DialogContent style={{ padding: 10, width: 'auto', minWidth: 400 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Paper style={{ padding: 10, width: 'auto', display: 'flex', flexDirection: 'column', gap: 8, minWidth: 400 }}>
                            {error && <Alert severity="error">{error}</Alert>} {/* Mostrar la alerta si hay un mensaje de error */}
                            
                            <TextField
                                label="Apellido"
                                value={apellido}
                                onChange={(e) => this.setState({ apellido: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="Nombre"
                                value={nombre}
                                onChange={(e) => this.setState({ nombre: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="Email"
                                value={email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                required
                                helperText="Una vez ingresado no se puede modificar ya que es el usuario del sistema"
                                    InputProps={{
                                        readOnly: isEditing,
                                }}
                                fullWidth
                            />
                            <TextField
                                label="C.U.I.T."
                                value={cuit}
                                onChange={(e) => this.setState({ cuit: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="Matricula"
                                value={matricula}
                                onChange={(e) => this.setState({ matricula: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="Nro. Afiliado Caja"
                                value={nroAfiliadoCaja}
                                onChange={(e) => this.setState({ nroAfiliadoCaja: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                select
                                label="Gremiales"
                                value={gremial}
                                onChange={(e) => this.setState({ gremial: e.target.value })}
                                fullWidth
                            >
                                {gremiales.map((oGremial) => (
                                    <MenuItem key={oGremial.id} value={oGremial.id}>
                                        {oGremial.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={habilitado}
                                        onChange={(e) => this.setState({ habilitado: e.target.checked })}
                                    />
                                }
                                label="Habilitado"
                            />
                            </Paper>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseModal}>Cancelar</Button>
                        <Button onClick={isEditing ? this.handleEditProfesional: this.handleAddProfesional} variant="contained" color="primary">
                            {isEditing ? 'Editar' : 'Agregar'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={confirmDisable} onClose={() => this.setState({ confirmDisable: false })}>
                    <DialogTitle>Confirmar Acci&oacute;n</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Estas seguro de que deseas modificar el estado del profesional?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmDisable: false })}>
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDisableProfesional} variant="contained" color="primary">
                            Cambiar Estado
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={confirmDelete} onClose={() => this.setState({ confirmDelete: false })}>
                    <DialogTitle>Confirmar Acci&oacute;n</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Estas seguro de que deseas eliminar el profesional?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmDelete: false })}>
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteProfesional} variant="contained" color="primary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={confirmReset} onClose={() => this.setState({ confirmReset: false })}>
                    <DialogTitle>Confirmar Acci&oacute;n</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Estas seguro de que deseas recuperar la password del profesional?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmReset: false })}>
                            Cancelar
                        </Button>
                        <Button onClick={this.handleResetProfesional} variant="contained" color="primary" startIcon={<LockResetIcon />}>
                            Recuperar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={5000}
                    onClose={() => this.setState({ showSnackbar: false })}
                >
                    <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default ProfesionalesList;

import React, { Component } from 'react';
import { useState } from 'react';
import { Alert, Typography, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, Tooltip } from '@mui/material';
import { FormControlLabel, Checkbox, Snackbar } from '@mui/material';
import { DataGrid, GridActionsCellItem, esES } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumb from './Breadcrumb';


/*import { Paper, Typography, TextField, Button, Alert, Stack } from '@mui/material';*/
import axios from 'axios';

axios.defaults.headers.common['Origin'] = window.location.origin;

class TipoEstampillaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            loading: true,
            openModal: false,

            tipo: '',
            codigo: '',
            precioVenta: '',
            urlImagen: '',
            habilitada: false,
            gremiales: [],
            gremial: '',

            selectedRow: null,
            isEditing: false, // True es un evento nuevo y False es Edici�n de un evento existente;
            showSnackbar: false,
            snackbarMessage: '',
            snackbarSeverity: 'success',
            error: '',            
            confirmDisable: false,
            confirmDelete: false,
        };
    }

    isAuthenticated() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        return userData !== null;
    }

    componentDidMount() {
        this.ObtenerTiposEstampillas();
        this.ObtenerGremiales();
    }

    handleConfirmDisable = () => {
        this.setState({ confirmDisable: true });
    };

    handleConfirmDelete = () => {
        this.setState({ confirmDelete: true });
    };

    handleShowSnackbar = (message, severity) => {
        this.setState({
            showSnackbar: true,
            snackbarMessage: message,
            snackbarSeverity: severity || 'success', // Si no se proporciona un tipo de alerta, se usa 'success' por defecto
        });
    };
    

    handleEdit = (selectedRow) => {
        console.log('Editar Tipo Estampilla:', selectedRow);
        // L�gica para editar el profesional con el ID proporcionado        
        const isEditing = true; // Comprueba si hay un evento seleccionado
        this.setState({
            selectedRow: selectedRow,
            openModal: true,
            tipo: selectedRow.tipo,
            codigo: selectedRow.codigo,
            precioVenta: selectedRow.precioVenta,
            urlImagen: selectedRow.urlImagen,
            habilitada: selectedRow.habilitada,
            gremial: selectedRow.idGremial,            
            isEditing: isEditing,
        });
    };

    handleDisable = (selectedRow) => {
        console.log('Deshabilitar Tipo de Estampilla:', selectedRow);
        // L�gica para deshabilitar el profesional con el ID proporcionado        
        this.setState({
            selectedRow: selectedRow,
            tipo: selectedRow.tipo,
            codigo: selectedRow.codigo,
            precioVenta: selectedRow.precioVenta,
            urlImagen: selectedRow.urlImagen,
            habilitada: selectedRow.habilitada,
            gremial: selectedRow.idGremial,            
        });
        this.handleConfirmDisable();
        //this.handleDisableEvent();


    };

    handleDelete = (selectedRow) => {
        console.log('Eliminar Tipo de Estampilla:', selectedRow);
        // L�gica para eliminar el profesional con el ID proporcionado
        this.setState({
            selectedRow: selectedRow,
            tipo: selectedRow.tipo,
            codigo: selectedRow.codigo,
            precioVenta: selectedRow.precioVenta,
            urlImagen: selectedRow.urlImagen,
            habilitada: selectedRow.habilitada,
            gremial: selectedRow.idGremial,            
        });
        this.handleConfirmDelete();
        //this.handleDisableEvent();
    };

    handleOpenModal = () => {
        const isEditing = !!this.state.selectedRow; // Comprueba si hay un evento seleccionado
        this.setState({
            openModal: true,
            isEditing: isEditing,
        });
    };

    handleCloseModal = () => {
        this.setState({
            openModal: false,
            tipo: '',
            codigo: '',
            precioVenta: '',
            urlImagen: '',
            habilitada: false,
            gremial: '',
            selectedRow: null,
            isEditing: false, // Restablecer el valor de isEditing
            error: '',
        });
    };

    renderCell = (params) => {
        const { row } = params;
        const handleEdit = () => this.handleEdit(row);
        const handleDelete = () => this.handleDelete(row);
        const handleDisable = () => this.handleDisable(row);

        return (
            <>
                <Tooltip title="Editar" placement="bottom" disabled>
                    <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Editar"
                    className="textPrimary"
                    onClick={handleEdit}
                    color="inherit"
                    />
                </Tooltip>
                <Tooltip title="Cambiar Estado" placement="bottom" disabled>
                    <GridActionsCellItem
                        icon={<LoopIcon />}
                        label="Deshabilitar"
                        onClick={handleDisable}
                        color="inherit"
                        />
                </Tooltip>
                <Tooltip title="Eliminar" placement="bottom" disabled>
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDelete}
                        color="inherit"
                    />
                </Tooltip>
            </>
        );
    };


    handleAddTipoEstampilla = () => {
        const { tipo, codigo, precioVenta, urlImagen, habilitada, gremial } = this.state;

        // Validar campos obligatorios
        if (!tipo || !codigo || !precioVenta || !urlImagen || !gremial) {
            this.setState({ error: 'Por favor, completa todos los campos obligatorios' });
            return;
        }

        // Resto de la l�gica para agregar el evento
        axios
            .post('tipoEstampillas/AgregarTipoEstampilla', {
                tipo: tipo,
                codigo: codigo,
                precioVenta: precioVenta,
                urlImagen: urlImagen,                
                habilitada: habilitada,
                idGremial: gremial,
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Tipo Estampilla agregado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerTiposEstampillas();
                this.handleShowSnackbar('Tipo Estampilla Agregado con exito', 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.handleCloseModal();
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al agregar el tipo de estampilla:', error);
                this.handleShowSnackbar('Error al agregar el tipo de estampilla', 'error');
                // Cerrar el di�logo modal y limpiar los campos
                this.handleCloseModal();
            });

        
    };

    handleEditTipoEstampilla = () => {
        const { tipo, codigo, precioVenta, urlImagen, habilitada, gremial, selectedRow } = this.state;

        // Validar campos obligatorios
        if (!tipo || !codigo || !precioVenta || !urlImagen || !habilitada || !gremial) {
            this.setState({ error: 'Por favor, completa todos los campos obligatorios' });
            return;
        }

       
        // Resto de la l�gica para agregar el evento
        axios
            .post('tipoEstampillas/EditarTipoEstampilla', {
                id: selectedRow.id,
                tipo: tipo,
                codigo: codigo,
                precioVenta: precioVenta,
                urlImagen: urlImagen,
                habilitada: habilitada,
                idGremial: gremial,
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Tipo Estampilla modificado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerTiposEstampillas();
                this.handleShowSnackbar('Tipo Estampilla modificado con exito', 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.handleCloseModal();
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al editar el tipo de estampilla:', error);
                // Cerrar el di�logo modal y limpiar los campos
                this.handleShowSnackbar('Error al modificar el tipo de estampilla', 'error');
                this.handleCloseModal();
            });
    };

    handleDisableTipoEstampilla = () => {
        
        const { tipo, codigo, precioVenta, urlImagen, habilitada, gremial, selectedRow } = this.state;

        // Resto de la l�gica para agregar el evento
        axios
            .post('tipoEstampillas/CambiarEstadoTipoEstampilla', {
                id: selectedRow.id,
                tipo: tipo,
                codigo: codigo,
                precioVenta: precioVenta,
                urlImagen: urlImagen,
                habilitada: habilitada ? false : true,
                idGremial: gremial,                   
            })
            .then((response) => {
                // Procesa la respuesta si es necesario
                console.log('Tipo Estampilla cambio estado con �xito:', response.data);
                // Volver a obtener los eventos activos para actualizar la grilla
                this.ObtenerTiposEstampillas();
                this.handleShowSnackbar('Cambio de estado realizado con exito', 'success'); // Mostrar el Snackbar
                // Cerrar el di�logo modal y limpiar los campos
                this.setState({ confirmDisable: false });
            })
            .catch((error) => {
                // Maneja el error si ocurre
                console.error('Error al cambiar estado del Tipo de Estampilla:', error);
                // Cerrar el di�logo modal y limpiar los campos
                this.handleShowSnackbar('No se pudo realizar el cambio de estado', 'error');
                this.setState({ confirmDisable: false });
            });
    };

    //handleDeleteProfesional = () => {

    //    const { apellido, nombre, email, matricula, nroAfiliadoCaja, habilitado, gremial, selectedRow } = this.state;

    //    // Resto de la l�gica para agregar el evento
    //    axios
    //        .post('profesionales/EliminarProfesional', {
    //            id: selectedRow.id,
    //            apellido: apellido,
    //            nombre: nombre,
    //            email: email,
    //            matricula: matricula,
    //            nroAfiliadoCaja: nroAfiliadoCaja,
    //            habilitado: habilitado,
    //            idGremial: gremial,
    //        })
    //        .then((response) => {
    //            // Procesa la respuesta si es necesario
    //            console.log('Profesional eliminado con �xito:', response.data);
    //            // Volver a obtener los eventos activos para actualizar la grilla
    //            this.ObtenerTiposEstampillas();
    //            this.handleShowSnackbar('Profesional eliminado con exito', 'success'); // Mostrar el Snackbar
    //            // Cerrar el di�logo modal y limpiar los campos
    //            this.setState({ confirmDelete: false });
    //        })
    //        .catch((error) => {
    //            // Maneja el error si ocurre
    //            console.error('Error al eliminar el profesional:', error);
    //            // Cerrar el di�logo modal y limpiar los campos
    //            this.handleShowSnackbar('No se puede eliminar el profesional', 'error');
    //            this.setState({ confirmDelete: false });
    //        });
    //};

    
    ObtenerTiposEstampillas = () => {
        axios
            .get('tipoestampillas/ObtenerTipoEstampillas')
            .then((response) => {
                this.setState({ rows: response.data, loading: false });
            })
            .catch((error) => {
                console.error('Error obteniendo tipos de estampillas', error);
            });
    };

    ObtenerGremiales = () => {
        axios
            .get('tipoestampillas/ObtenerGremiales')
            .then((response) => {
                this.setState({ gremiales: response.data }, () => {
                    console.log(this.state.gremiales); // Esto mostrar� el estado actualizado
                });
            })
            .catch((error) => {
                console.error('Error obteniendo gremiales', error);
            });
    };

    render() {

        const { error, rows, loading, openModal, tipo, codigo, precioVenta, porcentajeGremial, estampillaCaja, urlImagen, habilitada, gremiales, gremial, isEditing, showSnackbar, snackbarSeverity, snackbarMessage, confirmDisable, confirmDelete } = this.state;
        console.log(this.state.gremiales.nombre);

        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'ARS',
        });

        const percentageFormatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2, // Puedes ajustar esto seg�n tus necesidades
            maximumFractionDigits: 2,
        });

        const columns = [
            { field: 'tipo', headerName: 'Tipo', width: 80 },
            { field: 'codigo', headerName: 'Codigo', width: 80 },
            {
                field: 'precioVenta',
                headerName: 'Valor',
                width: 150,
                type: 'number',
                valueFormatter: ({ value }) => currencyFormatter.format(value),
            },
            {
                field: 'porcentajeGremial',
                headerName: '% Gremial',
                width: 150,
                type: 'number',
                valueFormatter: ({ value }) => percentageFormatter.format(value / 100),
            },
            /*{ field: 'urlImagen', headerName: 'Url Imagen', width: 180 },*/
            {
                field: 'GremialA', headerName: 'Gremial', width: 100,
                valueGetter: (params) => {
                    const GremialId = params.row.idGremial;
                    const gremial = this.state.gremiales.find(gremial => gremial.id === GremialId);
                    return gremial ? gremial.sigla : '';
                },
            },
            { field: 'estampillaCaja', headerName: 'Estampilla CAC', type: 'boolean', width: 150 }, 
            { field: 'habilitada', headerName: 'Habilitado', type: 'boolean', width: 100 }, 
            {
                field: 'actions',
                headerName: 'Acciones',
                width: 160,
                sortable: false,
                filterable: false,
                renderCell: this.renderCell,
            },
        ];

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Breadcrumb text='Tipos Estampilla' />
                <Typography variant="h5" component="h1" align="left" gutterBottom>
                    Tipos de Estampillas
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                    <Button size="small" onClick={this.handleOpenModal}>
                        Agregar Tipo
                    </Button>
                </Stack>
                <DataGrid 
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    loading={loading}
                    autoHeight={true}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    
                />

                <Dialog open={openModal} onClose={this.handleCloseModal}>
                    <DialogTitle>{isEditing ? 'Editar Tipo' : 'Nuevo Tipo' }</DialogTitle>
                    <DialogContent style={{ padding: 10, width: 'auto', minWidth: 400 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Paper style={{ padding: 10, width: 'auto', display: 'flex', flexDirection: 'column', gap: 8, minWidth: 400 }}>
                            {error && <Alert severity="error">{error}</Alert>} {/* Mostrar la alerta si hay un mensaje de error */}
                            
                            <TextField
                                label="Tipo"
                                value={tipo}
                                onChange={(e) => this.setState({ tipo: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="codigo"
                                value={codigo}
                                onChange={(e) => this.setState({ codigo: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="Precio Venta"
                                value={precioVenta}
                                onChange={(e) => this.setState({ precioVenta: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                label="Url Imagen"
                                value={urlImagen}
                                onChange={(e) => this.setState({ urlImagen: e.target.value })}
                                required
                                fullWidth
                            />
                            <TextField
                                select
                                label="Gremiales"
                                value={gremial}
                                onChange={(e) => this.setState({ gremial: e.target.value })}
                                fullWidth
                            >
                                {gremiales.map((oGremial) => (
                                    <MenuItem key={oGremial.id} value={oGremial.id}>
                                        {oGremial.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={habilitada}
                                        onChange={(e) => this.setState({ habilitada: e.target.checked })}
                                    />
                                }
                                label="Habilitado"
                            />
                            </Paper>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseModal}>Cancelar</Button>
                        <Button onClick={isEditing ? this.handleEditTipoEstampilla: this.handleAddTipoEstampilla} variant="contained" color="primary">
                            {isEditing ? 'Editar' : 'Agregar'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={confirmDisable} onClose={() => this.setState({ confirmDisable: false })}>
                    <DialogTitle>Confirmar Accion</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Estas seguro de que deseas modificar el estado del Tipo de Estampilla?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmDisable: false })}>
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDisableTipoEstampilla} variant="contained" color="primary">
                            Cambiar Estado
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={confirmDelete} onClose={() => this.setState({ confirmDelete: false })}>
                    <DialogTitle>Confirmar Accion</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Estas seguro de que deseas eliminar el Tipo de Estampilla?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmDelete: false })}>
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteProfesional} variant="contained" color="primary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ showSnackbar: false })}
                >
                    <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default TipoEstampillaList;

import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import Home1 from "./components/Home1";
import Login from "./components/LoginUser";
import ProfesionalesList from "./components/ProfesionalesList";
import TipoEstampillaList from "./components/TipoEstampillaList";
import Breadcrumb from "./components/Breadcrumb";
import GenerarEstampilla from "./components/GenerarEstampilla";
import EstampillasList from "./components/EstampillasList";
import EstadisticasProfesional from "./components/EstadisticasProfesional";
import Gauge from "./components/Gauge";
import ResetPass from "./components/ResetPass";
import CentrosSaludList from "./components/CentrosSaludList";
import ReporteEstampillasMes from "./components/ReporteEstampillasMes";
import ReportePacientesFecha from "./components/ReportePacientesFecha";
import PagoDemo from "./components/PagoDemo";


const AppRoutes = [
    {
        index: true,
        element: <Home />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/generarestampilla',
        element: <GenerarEstampilla />,
        requiresAuth: true, // No requiere autenticaci�n
        requiresRole: 'Profesional',
    },
    {
        path: '/estadisticasprofesional',
        element: <EstadisticasProfesional />,
        requiresAuth: true, // No requiere autenticaci�n
        requiresRole: 'Profesional',
    },
    {
        path: '/fetch-data',
        element: <FetchData />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/home1',
        element: <Home1 />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/loginuser',
        element: <Login />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: '',
    },
    {
        path: '/resetpass',
        element: <ResetPass />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: '',
    },
    {
        path: '/profesionaleslist',
        element: <ProfesionalesList />,
        requiresAuth: true, // Requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/tipoestampillalist',
        element: <TipoEstampillaList />,
        requiresAuth: true, // Requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/pagodemo',
        element: <PagoDemo />,
        requiresAuth: false, // Requiere autenticaci�n
        requiresRole: '',
    },
    {
        path: '/reporteestampillasmes',
        element: <ReporteEstampillasMes />,
        requiresAuth: true, // Requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/reportepacientesfecha',
        element: <ReportePacientesFecha />,
        requiresAuth: true, // Requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/centrossaludlist',
        element: <CentrosSaludList />,
        requiresAuth: true, // Requiere autenticaci�n
        requiresRole: 'Administrador',
    },
    {
        path: '/estampillaslist',
        element: <EstampillasList />,
        requiresAuth: true, // Requiere autenticaci�n
        requiresRole: 'Profesional',
    },
    {
        path: '/breadcrumb',
        element: <Breadcrumb />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: '',
    },
    {
        path: '/gauge',
        element: <Gauge />,
        requiresAuth: false, // No requiere autenticaci�n
        requiresRole: '',
    },
];

export default AppRoutes;

import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem, esES } from '@mui/x-data-grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Card, CardContent, CardActions, Typography, Link, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Stack, Box } from '@mui/material';
import Breadcrumb from './Breadcrumb';
import GaugeChart from 'react-gauge-chart';
import Gauge from './Gauge';
import { useAuth } from './AuthProvider';

import axios from 'axios';
axios.defaults.headers.common['Origin'] = window.location.origin;

// Funci�n para obtener el nombre del mes
function getMonthName(monthNumber) {
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return months[monthNumber - 1]; // Resta 1 porque los �ndices de los arrays comienzan en 0
}

function obtenerNumeroDeMesActual() {
    const fecha = new Date();
    const numeroDeMes = fecha.getMonth() + 1;
    return numeroDeMes;
}

function EstadisticasProfesional() {
    const [estadisticas, setEstadisticas] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userData, setIsAuthenticated, setUserData } = useAuth();

    const mesActual = obtenerNumeroDeMesActual();

    useEffect(() => {
        ObtenerEstadisticas();        
    }, []);

    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
    }, [userData]);

    const ObtenerEstadisticas = () => {
        // Realizar la solicitud HTTP para obtener las estad�sticas del profesional
        axios
            .post('estampillas/ObtenerEstadisticasProfesional', {
                username: userData.username,
                dniPaciente: '',
            })
            .then(response => {
                const estadisticasFormateadas = response.data.map(item => ({
                    ...item,
                    month: getMonthName(item.month),
                }));
                setEstadisticas(estadisticasFormateadas);
                
                setLoading(false);
            })
            .catch(error => {
                console.error('Error obteniendo estad�sticas', error);
                setLoading(false);
            });
    };

    const columns = [
        { field: 'year', headerName: 'Periodo', width: 100 },
        { field: 'month', headerName: 'Mes', width: 100 },
        { field: 'cantidadEstampillas', headerName: 'Nro. Estampillas', align: 'center', width: 120 },
        { field: 'montoTotal', headerName: 'Monto Cobrado', width: 110, align: 'right', valueFormatter: ({ value }) => value !== undefined ? `$ ${value.toFixed(2)}` : '' }

    ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Breadcrumb text='Estad&iacute;sticas' />
            <img
                src="/images/logocaja.png"
                alt="Caja Arte Curar"
                style={{ maxWidth: '100%', height: 'auto', marginBottom: '16px', marginTop: '16px' }}
            />
            {/*<Typography variant="h5" component="h1" align="left" gutterBottom>*/}
            {/*    Estad&iacute;sticas del Profesional*/}
            {/*</Typography>*/}
            {loading ? (
                <p>Cargando...</p>
            ) : (
                <div style={{ display: 'flex', maxWidth: '1200px', margin: '0 auto' }}>
    {/* Divisi�n izquierda */}
    <div style={{ flex: 1 }}>
        <Card variant="outlined" style={{ marginBottom: '16px', marginRight: '8px' }}>
            <CardContent>
                <Typography variant="h6" component="h2">
                    Estampillas Generadas &Uacute;ltimos 12 meses
                </Typography>
                <div>
                    <DataGrid
                        rows={estadisticas}
                        columns={columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 4 } },
                        }}
                        pageSizeOptions={[4, 8, 12]}
                        loading={loading}
                        autoHeight={true}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </CardContent>

        </Card>
    </div>
    
    {/* Divisi�n derecha */}
    <div style={{ flex: 1 }}>
        <Card variant="outlined" style={{ marginBottom: '16px', marginRight: '8px' }}>
            <CardContent>
            <Typography variant="h6" component="h1" align="left" gutterBottom>
                Cantidad de Estampillas
            </Typography>
            <div style={{ width: '100%', height: '100%' }}>
                <BarChart width={500} height={312} data={estadisticas}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="cantidadEstampillas" fill="#8884d8" name="Cantidad Estampillas" />
                    {/*<Bar dataKey="montoTotal" fill="#82ca9d" name="Monto Total" />*/}
                </BarChart>
            </div>
        </CardContent>
    </Card>
    </div>
</div>

                    
            )}
        </div>
    );
}

export default EstadisticasProfesional;

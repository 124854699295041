import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem, esES } from '@mui/x-data-grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Card, CardContent, CardHeader, CardMedia, Typography, Link, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Stack, Box } from '@mui/material';
import Breadcrumb from './Breadcrumb';
import GaugeChart from 'react-gauge-chart';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useAuth } from './AuthProvider';

import axios from 'axios';
axios.defaults.headers.common['Origin'] = window.location.origin;

// Funci�n para obtener el nombre del mes
function getMonthName(monthNumber) {
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return months[monthNumber - 1]; // Resta 1 porque los �ndices de los arrays comienzan en 0
}
function TableroObjetivo({ cantidadGenerada, cantidadObjetivo, mes }) {
    const [estadisticas, setEstadisticas] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userData, setIsAuthenticated, setUserData } = useAuth();

    const ObtenerTableroCaja = () => {
        axios
            .post('estampillas/obtenertablerocaja', {
                username: userData.username,
                dniPaciente: '',
            })
            .then(response => {
                const estadisticasFormateadas = response.data.map(item => ({
                    ...item,
                    month: getMonthName(item.month),
                }));
                setEstadisticas(estadisticasFormateadas);

                setLoading(false);
            })
            .catch(error => {
                console.error('Error obteniendo estad�sticas', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        ObtenerTableroCaja();
    }, []);

    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
    }, [userData]);

    const porcentaje = (estadisticas.length > 0 ? estadisticas[0].cantidadEstampillas : 0)  / cantidadObjetivo;
    const diferencia = cantidadObjetivo - (estadisticas.length > 0 ? estadisticas[0].cantidadEstampillas : 0) ;

    const porpie = porcentaje*100;
    
    const datos = [
        { name: 'Completado', value: porpie },
        { name: 'Faltante', value: 100 - porpie },
    ];
    const colores = ['#20F56E', '#EBECEB'];

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.9;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
        return (
            <text x={x} y={y} fill="#333" textAnchor="middle" dominantBaseline="center">
                {name}
            </text>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px' }}>
            <Card variant="outlined" style={{ width: '100%', /*backgroundColor: '#D9DEDB'*/ }}>
                {/*<CardHeader>*/}
                {/*    Estampillas Generadas ({getMonthName(mes)})*/}
                {/*</CardHeader>*/}
                <CardContent>
                    <img
                        src="/images/logocaja.png" // Reemplaza esto con la ruta real de tu imagen PNG
                        alt="Caja Arte Curar" // Proporciona un texto alternativo para accesibilidad
                        style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }} // Estilos para controlar el tama�o de la imagen
                    />
                    <Typography variant="h6" component="h2">
                        Estampillas Generadas ({getMonthName(mes)})
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Est&aacute;s a {diferencia} estampillas de llegar a tu objetivo mensual.
                    </Typography>
                    {/*<GaugeChart*/}
                    {/*    id="g1"*/}
                    {/*    nrOfLevels={3}*/}
                    {/*    percent={porcentaje}*/}
                    {/*    textColor={'#FF33FA'}*/}
                    {/*    style={{ width: '250px', marginTop: '16px' }}*/}
                    {/*/>*/}
                    <div style={{ display: 'flex', marginTop: '8px'  }}>
                        <div style={{ flex: '1', marginRight: '16px', display: 'flex', flexDirection: 'column' }}>
                            <Card variant="outlined" style={{ marginBottom: '16px', /*backgroundColor: '#CAD1CD'*/ }}>
                                <CardContent style={{ textAlign: 'center' }}>
                                    <Typography variant="h4" component="h2" style={{ textAlign: 'center' }}>
                                        {estadisticas.length > 0 ? estadisticas[0].cantidadEstampillas : 0}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center' }}>
                                        Estampillas Generadas
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" style={{ textAlign: 'center' }}>
                                        Min:<span style={{ color: 'red' }}>10</span> - Objetivo:<span style={{ color: 'green' }}>{cantidadObjetivo}</span> 
                                    </Typography>
                                </CardContent>
                            </Card>
                        
                            <Card variant="outlined" style={{ marginBottom: '0px', /*backgroundColor: '#CAD1CD'*/ }}>
                                <CardContent>
                                    <Typography variant="h4" component="h2" style={{ textAlign: 'center' }}>
                                        {estadisticas.length > 0 ? estadisticas[0].montoTotal.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) : '0.00'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center' }}>
                                        Monto Cobrado
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
                            <Card variant="outlined" style={{ marginBottom: '0px', /*backgroundColor: '#CAD1CD'*/ }}>
                                <CardContent>
                                    <ResponsiveContainer width="100%" height={185}>
                                        <PieChart>
                                            <Pie
                                                data={datos}
                                                dataKey="value"
                                                nameKey="name"
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                paddingAngle={0}
                                                label={renderCustomizedLabel}
                                            >
                                                {datos.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={colores[index % colores.length]} />
                                                ))}
                                            </Pie>
                                            {/* Agregamos un c�rculo blanco en el centro */}
                                            <circle cx="50%" cy="50%" r="40" fill="#ffffff" />
                                            {/* Agregamos un texto en el centro */}
                                            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill="#333" fontSize="24">
                                                {porpie.toFixed(2)}%
                                            </text>
                                        </PieChart>
                                    </ResponsiveContainer>
                                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center' }}>
                                        Objetivo Mensual
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </CardContent>
            </Card>
            
                
        </div>
            
    );
}

export default TableroObjetivo;

import React, { useState, useRef, useEffect } from 'react';
import {
    TextField,
    Button,
    Paper,
    Typography,
    CircularProgress,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Box,
    Divider,
} from '@mui/material';
import axios from 'axios';
import { Alert } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import QRCode from 'qrcode.react';
import Breadcrumb from './Breadcrumb';
import { useAuth } from './AuthProvider';
import './generarEstampillaStyle.css';
axios.defaults.headers.common['Origin'] = window.location.origin;
function GenerarEstampilla() {
    const recaptchaRef = useRef();
    const { userData, setIsAuthenticated, setUserData } = useAuth();
    const [codigo, setCodigo] = useState('');
    const [fechaEmision, setFechaEmision] = useState('');
    const [tiposEstampilla, setTiposEstampilla] = useState([]);
    const [tipoEstampilla, setTipoEstampilla] = useState('');
    const [tiposFicha, setTiposFicha] = useState([]);
    const [tipoFicha, setTipoFicha] = useState('');
    const [valor, setValor] = useState(0);
    const [nombrePaciente, setNombrePaciente] = useState('');
    const [tiposDocumento, setTiposDocumento] = useState([]);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [dniPaciente, setDniPaciente] = useState('');
    const [apellidoPaciente, setApellidoPaciente] = useState('');
    const [emailPaciente, setEmailPaciente] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [dniError, setDniError] = useState('');

    const siteKey = '6Ld_QwUoAAAAAHWXLLnpNIctr2Ecos90z2HUXFI1';

    useEffect(() => {
        // Agrega el c�digo para verificar la puntuaci�n de reCAPTCHA v3 aqu�
        const verifyRecaptcha = async () => {
            try {
                const recaptchaValue = await recaptchaRef.current.executeAsync();
                // Enviar recaptchaValue al servidor y verificar la puntuaci�n en el servidor
                // Basado en la puntuaci�n, toma decisiones en tu l�gica del lado del servidor
                // Enviar recaptchaValue al servidor de reCAPTCHA para verificar la puntuaci�n
                //const response = await axios.post('https://www.google.com/recaptcha/api/siteverify', {
                //    secret: '6Ld_QwUoAAAAAPiS8pJj_oSh03xmA5-ykuwcqPIN', // Reemplaza con tu clave secreta
                //    response: recaptchaValue,
                //});

                //const data = response.data;
                /*const puntuacionRecaptcha = data.score;*/
                console.log('Token de reCAPTCHA v3:', recaptchaValue);
                /*console.log('Puntuaci�n de reCAPTCHA v3:', puntuacionRecaptcha);*/
            } catch (error) {
                console.error('Error al verificar reCAPTCHA v3:', error);
            }
        };

        verifyRecaptcha();
    }, []); // Se ejecuta una vez al montar el componente

    const handleRecaptchaChange = (value) => {
        // Puedes guardar el valor del reCAPTCHA en el estado si es necesario
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ARS',
    });

    const ObtenerTiposEstampilla = () => {
        axios
            .get('estampillas/obtenertiposestampilla')
            .then((response) => {
                setTiposEstampilla(response.data);
                console.log(tiposEstampilla);
                // Establecer el valor predeterminado si hay datos
                if (response.data.length > 0) {
                    setTipoEstampilla(response.data[0].id);
                }
            })
            .catch((error) => {
                console.error('Error obteniendo tipos de estampilla', error);
            });
    };

    const ObtenerTiposFicha = () => {
        axios
            .get('estampillas/obtenertiposficha')
            .then((response) => {
                setTiposFicha(response.data);
                // Establecer el valor predeterminado si hay datos
                if (response.data.length > 0) {
                    setTipoFicha(response.data[0].id);
                }
            })
            .catch((error) => {
                console.error('Error obteniendo tipos de certificados', error);
            });
    };

    const ObtenerTiposDocumento = () => {
        axios
            .get('estampillas/obtenertiposdocumento')
            .then((response) => {
                setTiposDocumento(response.data);
                // Establecer el valor predeterminado si hay datos
                if (response.data.length > 0) {
                    setTipoDocumento(response.data[0].id);
                }
            })
            .catch((error) => {
                console.error('Error obteniendo tipos de documentos', error);
            });
    };

    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
    }, [userData]);

    // Este useEffect se ejecutar� cuando el componente se monte
    useEffect(() => {
        // Obt�n la fecha actual en el formato "YYYY-MM-DD"
        const today = new Date().toISOString().split('T')[0];
        setFechaEmision(today);

        ObtenerTiposEstampilla();
        ObtenerTiposFicha();
        ObtenerTiposDocumento();
    }, []); // El segundo argumento [] indica que solo se debe ejecutar una vez (en el montaje)

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        switch (name) {
            case 'codigo':
                setCodigo(fieldValue);
                break;
            case 'fechaEmision':
                setFechaEmision(fieldValue);
                break;
            case 'tipoEstampilla':
                setTipoEstampilla(fieldValue);
                break;
            case 'tipoFicha':
                setTipoFicha(fieldValue);
                break;
            case 'valor':
                setValor(fieldValue);
                break;
            case 'nombrePaciente':
                setNombrePaciente(fieldValue);
                break;
            case 'tipoDocumento':
                setTipoDocumento(fieldValue);
                break;
            case 'dniPaciente':
                setDniPaciente(fieldValue);
                // Validar que sea num�rico y tenga como m�ximo 8 caracteres
                const dniPattern = /^\d{1,8}$/;
                if (!dniPattern.test(fieldValue)) {
                    setDniError('El DNI debe ser num�rico y tener como m�ximo 8 caracteres.');
                } else {
                    setDniError('');
                }
                break;
            case 'apellidoPaciente':
                setApellidoPaciente(fieldValue);
                break;
            case 'emailPaciente':
                setEmailPaciente(fieldValue);
                // Validar el correo electr�nico utilizando una expresi�n regular
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(fieldValue)) {
                    setEmailError('Por favor, ingrese un email valido.');
                } else {
                    
                    setEmailError('');
                }
                break;
            default:
                break;
        }
    };

    const handleShare = () => {
        if (dialogData) {
            const qrImageUrl = encodeURIComponent(dialogData.urlQR);
            const whatsappMessage = encodeURIComponent('Verificaci�n Estampilla Generada');
            const whatsappLink = `https://api.whatsapp.com/send?text=${whatsappMessage}%0A${qrImageUrl}`;
            window.open(whatsappLink, '_blank');
        }
        setShowDialog(false);
    };

    const handlePrint = () => {
        if (showDialog) {
            // Abrir el di�logo antes de imprimir
            window.print();
        }
    };

    const handleGenerarEstampilla = async () => {
        if (emailError || !dniPaciente || !apellidoPaciente || !nombrePaciente) {
            return; // Evitar la solicitud si hay errores o campos faltantes
        }

        try {
            const response = await axios.post('estampillas/Generar', {
                codigo,
                fechaEmision,
                idTipoEstampilla: tipoEstampilla,
                idTipoFicha: tipoFicha,
                idTipoDocumento: tipoDocumento,
                apellidoPaciente,
                nombrePaciente,
                dniPaciente,
                emailPaciente,
                username: userData.username,
            });

            const responseMessage = {
                mensaje: 'Estampilla generada exitosamente.',
                fechaEmision: response.data.fechaEmision,
                documento: response.data.tipoDNI + ': ' + response.data.dniPaciente,
                numeroEstampilla: response.data.numeroEstampilla,
                urlQR: response.data.urlQR,
                paciente: response.data.apellidoPaciente + ', ' + response.data.nombrePaciente,
                profesional: response.data.profesional,
                matricula: response.data.matricula,
                actoMedico: response.data.actoMedico,
                valorEstampilla: response.data.valorEstampilla,
            };

            setShowMessage(true);
            setSuccessMessage('Estampilla generada exitosamente.');
            setErrorMessage('');
            setShowDialog(true);
            setDialogData(responseMessage);
            setIsLoading(false);
            resetFormFields();
        } catch (error) {
            setShowMessage(false);
            setSuccessMessage('');
            setErrorMessage('Error al generar una estampilla. ' + error.response.data);
            setIsLoading(false);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        // Verifica el reCAPTCHA aqu� antes de enviar el formulario
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            // Mostrar un mensaje de error o realizar alguna acci�n si el reCAPTCHA no se complet�
            return;
        }

        handleGenerarEstampilla();
    };

    const resetFormFields = () => {
        const today = new Date().toISOString().split('T')[0];
        setCodigo('');
        setFechaEmision(today);
        setValor(0);
        setNombrePaciente('');
        setDniPaciente('');
        setApellidoPaciente('');
        setEmailPaciente('');
    };

    return (
        <div>
            <Breadcrumb text='Estampillas' />
            <div className={isLoading ? 'overlay' : ''}></div>
            <Paper elevation={3} style={{ padding: '20px', width: '400px', display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center', margin: '0 auto', }}>
                <Typography variant="h6" gutterBottom>
                    Generar Estampilla
                </Typography>
                {showMessage && (
                    <Alert severity={successMessage ? 'success' : 'error'} sx={{ mb: 2 }}>
                        {successMessage || errorMessage}
                    </Alert>
                )}
                <form onSubmit={handleFormSubmit}>
                    {/* Agrega los campos del formulario aqu� */}
                    <TextField
                        select
                        label="Tipo Estampilla Caja"
                        value={tipoEstampilla}
                        required
                        onChange={(e) => setTipoEstampilla(e.target.value)}
                        fullWidth
                    >
                        {tiposEstampilla.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>
                                {tipo.tipo}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Acto Medico"
                        value={tipoFicha}
                        required
                        onChange={(e) => setTipoFicha(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {tiposFicha.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>
                                {tipo.tipo}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Apellido"
                        name="apellidoPaciente"
                        value={apellidoPaciente}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        margin="dense"
                    />
                    <TextField
                        label="Nombre"
                        name="nombrePaciente"
                        value={nombrePaciente}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        margin="dense"
                    />
                    <TextField
                        select
                        label="Tipo de Documento"
                        name="tipoDocumento"
                        value={tipoDocumento}
                        onChange={(e) => setTipoDocumento(e.target.value)}
                        fullWidth
                        required
                        margin="dense"
                    >
                        {tiposDocumento.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>
                                {tipo.tipo}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="DNI"
                        name="dniPaciente"
                        value={dniPaciente}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        margin="dense"
                        error={Boolean(dniError)}
                    />
                    <TextField
                        label="Email"
                        value={emailPaciente}
                        type="email"
                        name="emailPaciente"
                        helperText="Email donde se envia la constancia de estampillado"
                        onChange={handleInputChange}
                        required
                        fullWidth
                        margin="dense"
                        error={Boolean(emailError)}
                    />
                    {/*{emailError && (*/}
                    {/*    <p style={{ color: 'darkred' }}>{emailError}</p>*/}
                    {/*)}*/}
                    <TextField
                        label="Fecha Emision"
                        type="date"
                        name="fechaEmision"
                        value={fechaEmision.substring(0, 10)}
                        onChange={handleInputChange}
                        required
                        disable
                        fullWidth
                        margin="dense"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <br />
                    {/* Agrega el reCAPTCHA */}
                    <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={siteKey}
                            size="invisible"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    {/* Agrega otros campos del formulario seg�n tus necesidades */}
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading} style={{ margin: 'dense' }} >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Generar Estampilla'}
                    </Button>
                </form>
            </Paper>

            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                PaperProps={{
                    style: {
                        minWidth: '700px', // Establece el ancho m�nimo deseado en p�xeles
                    },
                }}
            >
                <DialogTitle sx={{ backgroundColor: 'blue', color: 'white', fontWeight: 'bold', marginBottom: '8px' }}>Datos de la Estampilla (Ley 12.818)</DialogTitle>
                <DialogContent>

                    <img
                        src="/images/logocaja.png" // Reemplaza esto con la ruta real de tu imagen PNG
                        alt="Caja Arte Curar" // Proporciona un texto alternativo para accesibilidad
                        style={{ maxWidth: '100%', height: 'auto', marginBottom: '12px' }} // Estilos para controlar el tama�o de la imagen
                    />
                    <DialogContentText>
                        {dialogData ? dialogData.mensaje : ''}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={8} container alignItems="center">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    <strong>Fecha:</strong> {dialogData ? formatDateTime(dialogData.fechaEmision) : ''}
                                </Typography>
                                <Typography>
                                    <strong>Paciente:</strong> {dialogData ? dialogData.paciente + ' - ' + dialogData.documento : ''}
                                </Typography>
                                <Typography>
                                    <strong>N&uacute;mero de Estampilla:</strong> {dialogData ? dialogData.numeroEstampilla : ''}
                                </Typography>
                                <Typography>
                                    <strong>Acto M&eacute;dico:</strong> {dialogData ? dialogData.actoMedico : ''}
                                </Typography>
                                <Typography>
                                    <strong>Valor:</strong> {dialogData ? currencyFormatter.format(dialogData.valorEstampilla) : ''}
                                </Typography>
                                <Typography>
                                    <strong>Profesional:</strong> {dialogData ? dialogData.profesional + ' (' + dialogData.matricula + ')' : ''}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4} container alignItems="center">
                            <div style={{ width: '100%', marginTop: '16px' }}></div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                                {dialogData && dialogData.urlQR && (
                                    <Paper elevation={3}>
                                        <Box p={1}>
                                            <QRCode
                                                value={dialogData.urlQR}
                                            />
                                        </Box>
                                    </Paper>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" component="div" role="presentation" />
                    <Typography>
                        La presente estampilla se extiende a los fines del cumplimiento de lo normado en los art&iacute;culos 13 inciso C, 28 y 29 de la Ley 12.818.
                    </Typography>



                </DialogContent>
                <DialogActions className="dialog-actions-estampilla">
                    <Button className="print-hidden" onClick={() => setShowDialog(false)} color="primary">
                        Cerrar
                    </Button>
                    {/*<Button className="print-hidden" onClick={handleShare} color="primary">*/}
                    {/*    Compartir*/}
                    {/*</Button>*/}
                    <Button className="print-hidden" onClick={handlePrint} color="primary">
                        Imprimir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default GenerarEstampilla;

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Paper, Typography, TextField, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate
import { useAuth } from './AuthProvider';
import Breadcrumb from './Breadcrumb';

function ResetPass() {
    const { userData, setIsAuthenticated, updateUserData } = useAuth();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate(); // Usamos useNavigate para acceder a la funci�n navigate

    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
    }, [userData]);    

    const handleOldPassChange = (event) => {
        const value = event.target.value;
        setOldPassword(value);
        //validateOldPassword(value);
        //validateForm();
    };

    const handleNewPassChange = (event) => {
        const value = event.target.value;
        setNewPassword(value);
        //validateNewPassword(value);
        //validateOldPassword(oldPassword);
        //validateForm();
    };

    const handleRepPassChange = (event) => {
        const value = event.target.value;
        setRepeatPassword(value);
        //validateRepeatPassword(value);
        //validateForm();
    };

    const showSnackbar = (severity, message) => {
        setIsSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
    };

    const validateNewPassword = (value) => {
        if (value !== repeatPassword) {
            setRepeatPasswordError("Las passwords no coinciden");
        } else {
            setRepeatPasswordError("");
        }
    };

    const validateRepeatPassword = (value) => {
        if (value !== newPassword) {
            setRepeatPasswordError("Las passwords no coinciden");
        } else {
            setRepeatPasswordError("");
        }
    };

    const validateOldPassword = (value) => {
        if (value === newPassword) {
            setOldPasswordError("La password actual y nueva deben ser diferentes");
        } else {
            setOldPasswordError("");
        }
    };

    const validateForm = () => {
        const isOldPasswordValid = oldPassword !== newPassword;
        const isNewPasswordValid = newPassword === repeatPassword;
        setIsFormValid(isOldPasswordValid && isNewPasswordValid);
    };

    const handleOldPassBlur = () => {
        validateOldPassword(oldPassword);
        validateForm();
    };

    const handleNewPassBlur = () => {
        validateNewPassword(newPassword);
        validateOldPassword(oldPassword); // Tambi�n puedes validar oldPassword aqu� si es necesario
        validateForm();
    };

    const handleRepPassBlur = () => {
        validateRepeatPassword(repeatPassword);
        validateForm();
    };


    const hideSnackbar = () => {
        setIsSnackbarOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true); // Activar indicador de carga

        const formData = {
            username: userData.username,
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        axios
            .post('login/ChangePassword', formData)
            .then((response) => {
                // Actualizar los datos del usuario utilizando updateUserData del AuthProvider                
                showSnackbar('success', response.data);

                setTimeout(() => {
                    // Utiliza navigate para navegar a la p�gina principal sin recargar la p�gina
                    setIsLoading(false); // Desactivar indicador de carga
                    navigate('/');
                }, 2000);
            })
            .catch((error) => {
                showSnackbar('error', error.response.data);
                console.error(error);
                setIsLoading(false); // Desactivar indicador de carga
            });
    };


    return (
        <div>
            <Breadcrumb text='Usuario' />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '64vh' }}>
                <Paper style={{ padding: 10, width: 300 }}>
                    <Typography variant="h5" component="h1" align="center" gutterBottom>
                        Cambiar Password
                    </Typography>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                        <TextField
                            label="Password Actual"
                            type="password"
                            value={oldPassword}
                            onChange={handleOldPassChange}
                            onBlur={handleOldPassBlur}
                            required
                            error={!!oldPasswordError}
                            helperText={oldPasswordError}
                        />
                        <TextField
                            label="Password Nueva"
                            type="password"
                            value={newPassword}
                            onChange={handleNewPassChange}
                            onBlur={handleNewPassBlur}
                            required
                            error={!!newPasswordError}
                            helperText={newPasswordError}
                        />
                        <TextField
                            label="Repetir Password Nueva"
                            type="password"
                            value={repeatPassword}
                            onChange={handleRepPassChange}
                            onBlur={handleRepPassBlur}
                            required
                            error={!!repeatPasswordError}
                            helperText={repeatPasswordError}
                        />
                        <Button type="submit" variant="contained" color="primary" disabled = { isLoading || !isFormValid}>
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Actualizar'}
                        </Button>
                    </form>
                </Paper>
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={2000}
                    onClose={hideSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity={snackbarSeverity} onClose={hideSnackbar}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

export default ResetPass;

import React, { useState, useEffect } from 'react';
import {
    Alert,
    Typography,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Stack,
    Tooltip,
    Box,
    InputAdornment,
    Snackbar,
    Divider,
    Grid,
} from '@mui/material';
import {
    DataGrid,
    GridActionsCellItem,
    esES,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumb from './Breadcrumb';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import PreviewIcon from '@mui/icons-material/Preview';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useAuth } from './AuthProvider';
import './generarEstampillaStyle.css';
import axios from 'axios';

axios.defaults.headers.common['Origin'] = window.location.origin;

function EstampillasList() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [codigo, setCodigo] = useState('');
    const [fechaEmision, setFechaEmision] = useState('');
    const [tiposEstampilla, setTiposEstampilla] = useState([]);
    const [tipoEstampilla, setTipoEstampilla] = useState('');
    const [tiposFicha, setTiposFicha] = useState([]);
    const [tipoFicha, setTipoFicha] = useState('');
    const [valor, setValor] = useState(0);
    const [nombrePaciente, setNombrePaciente] = useState('');
    const [tiposDocumento, setTiposDocumento] = useState([]);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [dniPaciente, setDniPaciente] = useState('');
    const [apellidoPaciente, setApellidoPaciente] = useState('');
    const [emailPaciente, setEmailPaciente] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [error, setError] = useState('');
    const [searchDni, setSearchDni] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [showDialogMail, setShowDialogMail] = useState(false);
    const [dialogMailData, setDialogMailData] = useState(null);
    const [alternateEmail, setAlternateEmail] = useState('');
    const { userData, setIsAuthenticated, setUserData } = useAuth();
    const [emailError, setEmailError] = useState('');

    const isAuthenticated = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        return userData !== null;
    };

    const handleShowSnackbar = (message, severity) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'success');
    };

    const renderCell = (params) => {
        const { row } = params;

        const handleSendMail = () => handleOpenDialogMail(row);
        const handleView = () => handleOpenDialog(row);

        return (
            <>
                <Tooltip title="Ver" placement="bottom">
                    <GridActionsCellItem
                        icon={<PreviewIcon />}
                        label="Ver"
                        className="textPrimary"
                        onClick={handleView}
                        color="inherit"
                    />
                </Tooltip>
                <Tooltip title="Enviar x Correo" placement="bottom">
                    <GridActionsCellItem
                        icon={<EmailIcon />}
                        label="Enviar"
                        className="textPrimary"
                        onClick={handleSendMail}
                        color="inherit"
                    />
                </Tooltip>
                {/*<Tooltip title="Compartir" placement="bottom">
                    <GridActionsCellItem
                        icon={<ShareIcon />}
                        label="Compartir"
                        onClick={handleShare}
                        color="inherit"
                    />
                </Tooltip>*/}
            </>
        );
    };

    const handleOpenDialog = (rowData) => {
        setShowDialog(true);
        setDialogData(rowData);
    };

    const handleOpenDialogMail = (rowData) => {
        setShowDialogMail(true);
        setDialogMailData(rowData);
        setAlternateEmail(rowData ? rowData.emailPaciente : '');
    };

    const handleSearchInputChange = (e) => {
        setSearchDni(e.target.value);
    };

    const handleSearchByDni = () => {
        // Realiza la b�squeda aqu� usando searchDni
        ObtenerEstampillasDNI();
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handlePrint = () => {        
        window.print();
        setShowDialog(false);
    };

    const handleSend = () => {
        if (emailError) {
            return; // Evitar la solicitud si hay errores o campos faltantes
        }
        setShowDialogMail(false);
        // Ejecutar POST para enviar email
        axios
            .post('estampillas/enviaremail', {
                numeroEstampilla: dialogMailData.numeroEstampilla,
                emailAlternativo: alternateEmail,
            })
            .then((response) => {
                setShowSnackbar(true);
                setSnackbarSeverity('success');
                setSnackbarMessage(response.data);
                console.log("Envio el email")
            })
            .catch((error) => {
                setShowSnackbar(true);
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data);
                console.log("Error en el emali" + error.response)
            });
    }

    const handleShare = () => {       
        window.print();
        setShowDialog(false);
    };

    const ObtenerEstampillas = () => {
        axios
            .post('estampillas/obtenerestampillasprofesional', {
                username: userData.username,
                dniPaciente: '',
            })
            .then((response) => {
                setRows(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error obteniendo profesionales', error);
            });
    };

    const ObtenerEstampillasDNI = () => {
        axios
            .post('estampillas/obtenerestampillasprofesionalxdni', {
                username: userData.username,
                dniPaciente: searchDni,
            })
            .then((response) => {
                setRows(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error obteniendo profesionales', error);
            });
    };

    const handleAlternateEmailChange = (e) => {
        setAlternateEmail(e.target.value);
        // Validar el correo electr�nico utilizando una expresi�n regular
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(e.target.value)) {
            setEmailError('Por favor, ingrese un email valido.');
        } else {

            setEmailError('');
        }
    };

    useEffect(() => {
        ObtenerEstampillas();
        const today = new Date().toISOString().split('T')[0];
        setFechaEmision(today);
    }, []);

    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
    }, [userData]);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ARS',
    });

    const columns = [
        {
            field: 'fechaEmision',
            headerName: 'Fecha Emision',
            width: 180,
            type: 'datetime',
            width: 130,
            valueFormatter: (params) => formatDateTime(params.value),
        },
        { field: 'tipoDNI', headerName: 'Tipo', width: 60 },
        { field: 'dniPaciente', headerName: 'DNI', width: 100 },
        { field: 'apellidoPaciente', headerName: 'Apellido', width: 120 },
        { field: 'nombrePaciente', headerName: 'Nombre', width: 120 },
        { field: 'numeroEstampilla', headerName: 'Nro. Estampilla', width: 200 },
        { field: 'codigoEstampilla', headerName: 'Tipo Estampilla', width: 80 },
        {
            field: 'valorEstampilla',
            headerName: 'Valor',
            width: 120,
            type: 'number',
            valueFormatter: ({ value }) => currencyFormatter.format(value),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 160,
            sortable: false,
            filterable: false,
            renderCell: renderCell,
        },
    ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Breadcrumb text='Estampillas' />
            <Typography variant="h5" component="h1" align="left" gutterBottom>
                Estampillas Generadas Profesional
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Button
                    component={Link}
                    to="/generarestampilla"
                    variant="outlined"
                    size="small"
                    startIcon={<QrCode2Icon />}
                >
                    Generar Estampilla
                </Button>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Buscar por DNI"
                        value={searchDni}
                        onChange={handleSearchInputChange}
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={handleSearchByDni}
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <SearchIcon />
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </Stack>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                }}
                pageSizeOptions={[15, 30, 50]}
                loading={loading}
                autoHeight={true}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                className="print-hidden"
            />

            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                PaperProps={{
                    style: {
                        minWidth: '700px', // Establece el ancho m�nimo deseado en p�xeles
                    },
                }}
            >
                <DialogTitle sx={{ backgroundColor: 'blue', color: 'white', fontWeight: 'bold', marginBottom: '8px' }}>Datos de la Estampilla (Ley 12.818)</DialogTitle>
                <DialogContent>
                    
                    <img
                        src="/images/logocaja.png" // Reemplaza esto con la ruta real de tu imagen PNG
                        alt="Caja Arte Curar" // Proporciona un texto alternativo para accesibilidad
                        style={{ maxWidth: '100%', height: 'auto', marginBottom: '12px' }} // Estilos para controlar el tama�o de la imagen
                    />
                    <DialogContentText>
                        {dialogData ? dialogData.mensaje : ''}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={8} container alignItems="center">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    <strong>Fecha:</strong> {dialogData ? formatDateTime(dialogData.fechaEmision) : ''}
                                </Typography>
                                <Typography>
                                    <strong>Paciente:</strong> {dialogData ? dialogData.apellidoPaciente + ', ' + dialogData.nombrePaciente + ' (' + dialogData.dniPaciente + ')' : ''}
                                </Typography>
                                <Typography>
                                    <strong>N&uacute;mero de Estampilla:</strong> {dialogData ? dialogData.numeroEstampilla : ''}
                                </Typography>
                                <Typography>
                                    <strong>Acto M&eacute;dico:</strong> {dialogData ? dialogData.actoMedico : ''}
                                </Typography>
                                <Typography>
                                    <strong>Valor:</strong> {dialogData ? currencyFormatter.format(dialogData.valorEstampilla) : ''}
                                </Typography>
                                <Typography>
                                    <strong>Profesional:</strong> {dialogData ? dialogData.profesional + ' (' + dialogData.matricula + ')' : ''}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4} container alignItems="center">
                            <div style={{ width:'100%', marginTop: '16px'}}></div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                                {dialogData && dialogData.urlQR && (
                                    <Paper elevation={3}>
                                        <Box p={1}>
                                            <QRCode
                                                value={dialogData.urlQR}
                                            />
                                        </Box>
                                    </Paper>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" component="div" role="presentation" />
                    <Typography>
                        La presente estampilla se extiende a los fines del cumplimiento de lo normado en los art&iacute;culos 13 inciso C, 28 y 29 de la Ley 12.818.
                    </Typography>
                    
                    
                    
                </DialogContent>
                <DialogActions className="dialog-actions-estampilla">
                    <Button className="print-hidden" onClick={handlePrint} color="primary" startIcon={<PrintIcon />}>
                        Imprimir
                    </Button>
                    <Button className="print-hidden" onClick={() => setShowDialog(false)} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDialogMail}
                onClose={() => setShowDialogMail(false)}
                PaperProps={{
                    style: {
                        minWidth: '700px', // Establece el ancho m�nimo deseado en p�xeles
                    },
                }}
            >
                <DialogTitle sx={{ backgroundColor: 'blue', color: 'white', fontWeight: 'bold', marginBottom: '8px' }}>Enviar por correo estampilla generada</DialogTitle>
                <DialogContent>

                    <img
                        src="/images/logocaja.png" // Reemplaza esto con la ruta real de tu imagen PNG
                        alt="Caja Arte Curar" // Proporciona un texto alternativo para accesibilidad
                        style={{ maxWidth: '100%', height: 'auto', marginBottom: '12px' }} // Estilos para controlar el tama�o de la imagen
                    />
                    <DialogContentText>
                        {dialogMailData ? dialogMailData.mensaje : ''}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={8} container alignItems="center">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    <strong>Fecha:</strong> {dialogMailData ? formatDateTime(dialogMailData.fechaEmision) : ''}
                                </Typography>
                                <Typography>
                                    <strong>Paciente:</strong> {dialogMailData ? dialogMailData.apellidoPaciente + ', ' + dialogMailData.nombrePaciente + ' (' + dialogMailData.dniPaciente + ')' : ''}
                                </Typography>
                                <Typography>
                                    <strong>N&uacute;mero de Estampilla:</strong> {dialogMailData ? dialogMailData.numeroEstampilla : ''}
                                </Typography>
                                <Typography>
                                    <strong>Acto M&eacute;dico:</strong> {dialogMailData ? dialogMailData.actoMedico : ''}
                                </Typography>
                                <Typography>
                                    <strong>Valor:</strong> {dialogMailData ? currencyFormatter.format(dialogMailData.valorEstampilla) : ''}
                                </Typography>
                                <Typography>
                                    <strong>Profesional:</strong> {dialogMailData ? dialogMailData.profesional + ' (' + dialogMailData.matricula + ')' : ''}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4} container alignItems="center">
                            <div style={{ width: '100%', marginTop: '16px' }}></div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                                {dialogMailData && dialogMailData.urlQR && (
                                    <Paper elevation={3}>
                                        <Box p={1}>
                                            <QRCode
                                                value={dialogMailData.urlQR}
                                                style={{ width: '80px', height: '80px' }}
                                            />
                                        </Box>
                                    </Paper>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: '16px' }}></div>
                    <Divider variant="middle" component="div" role="presentation" />
                    <div style={{ marginBottom: '16px' }}></div>
                    <TextField
                        label="Email"
                        value={alternateEmail}
                        type="email"
                        name="emailPaciente"
                        helperText="Por defecto es el mail del Paciente declarado pero puede ingresar otro"
                        onChange={handleAlternateEmailChange}
                        required
                        fullWidth
                        margin="dense"
                        error={Boolean(emailError)}
                    />
                </DialogContent>
                <DialogActions className="dialog-actions-estampilla">
                    <Button className="print-hidden" onClick={handleSend} color="primary" startIcon={<EmailIcon />}>
                        Enviar
                    </Button>
                    <Button className="print-hidden" onClick={() => setShowDialogMail(false)} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>            

            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default EstampillasList;


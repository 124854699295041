import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Gauge from './Gauge';
function Home() {

    const { userData, setIsAuthenticated, setUserData } = useAuth();
    useEffect(() => {
        // Se ejecutar� cada vez que cambie el estado en AuthProvider
    }, [userData]);

    // Obtener el n�mero de mes actual
    const mesActual = new Date().getMonth() + 1; // +1 porque los meses son indexados desde 0

    // Aca se arma el Home de Acuerdo al Role que trae el usuario que se logueo (Administrador, Profesional, ...)
    const renderCardsBasedOnUserRole = () =>
    {
        if (userData === null)
        {
            return (
                <></>
            );
        }
        if (userData.role === 'Administrador') {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ minHeight: 200 }}>
                            <CardContent>
                                <Typography variant="h6" component="h2">
                                    Reportes
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gestiona los reportes de administraci&oacute;n
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to="/reporteestampillasmes" size="small" variant="outlined">Estampillas por Mes</Button>
                            </CardActions>
                            <CardActions>
                                <Button component={Link} to="/reportepacientesfecha" size="small" variant="outlined">Pacientes</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ minHeight: 200 }}>
                            <CardContent>
                                <Typography variant="h6" component="h2">
                                    Administrador de Profesionales
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gestiona los profesionales asociados a la gremial
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to="/profesionaleslist" size="small" variant="outlined">Profesionales</Button>
                                <Button component={Link} to="/centrossaludlist" size="small" variant="outlined">Centros Salud</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ minHeight: 200 }}>
                            <CardContent>
                                <Typography variant="h6" component="h2">
                                    Administrador Gremial
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gestiona la configuracion de la gremial
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to="/tipoestampillalist" size="small" variant="outlined">Tipos de Estampillas</Button>
                                {/*<Button component={Link} to="/pagodemo" size="small" variant="outlined">Test Pago MP</Button>*/}
                            </CardActions>
                        </Card>
                    </Grid>
                </>
            );
        }
        else
        {
            return (
                <>
                    {/*                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        Administrador de Estampillas
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Gestiona los estampillas del profesional medico
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to="/generarestampilla" size="small" variant="outlined">Generar</Button>
                                    <Button component={Link} to="/estampillaslist" size="small" variant="outlined">Lista</Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        Reportes
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Informaci�n de gesti�n para el profesional.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to="/estadisticasprofesional" size="small" variant="outlined">Tablero de Ingresos</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>*/}

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Gauge cantidadGenerada='75' cantidadObjetivo='100' mes={mesActual} />
                        </Grid>

                        {/*<Grid item xs={6}>*/}
                        {/*    <Gauge cantidadGenerada='75' cantidadObjetivo='100' mes='2' />*/}
                        {/*</Grid>*/}
                    </Grid>
        
                </>
            );
        }
    }
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Grid container spacing={2}>
                    {renderCardsBasedOnUserRole()}        
                </Grid>
            </div>
        </div>
    );
}

export default Home;
